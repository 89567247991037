import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../../common/error-handler';
import { CreateActionWebRequestDto } from '../../../../../src/configuration/action/dto/create-action-web-request.dto';
import { ActionWebResponseDto } from '../../../../../src/configuration/action/dto/action-web-response.dto';

const apiUrl = '/api/configuration';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ConfigurationActionService {
  constructor(private readonly http: HttpClient) {}

  public requestAction(
    request: CreateActionWebRequestDto,
  ): Observable<ActionWebResponseDto> {
    return this.http
      .post<ActionWebResponseDto>(apiUrl, request, httpOptions)
      .pipe(catchError(ErrorHandler.handleError<ActionWebResponseDto>(null)));
  }
}
