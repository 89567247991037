import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UpdateConfigurationDto } from "../../../../src/configuration/dto/update-configuration.dto";
import { firstValueFrom, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorHandler } from "../common/error-handler";
import { ConfigurationKey } from '../../../../src/configuration/dto/configuration.dto';
import { AllegroDeviceRequestResponse, AllegroUserDataResponse } from '../../../../src/allegro/allegro-api/allegro-api.service';

const apiUrl = '/api/configuration';
const authApiUrl = '/api/allegro-auth';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private readonly http: HttpClient) {}

  getConfigByKey(key: ConfigurationKey): Observable<UpdateConfigurationDto>{
    return this.http
      .get<UpdateConfigurationDto>(`${apiUrl}/byKey/${key}`)
      .pipe(
        catchError(ErrorHandler.handleError('config', null)),
      );
  }

  getConfigs(): Observable<UpdateConfigurationDto[]> {
    return this.http
      .get<UpdateConfigurationDto[]>(`${apiUrl}`)
      .pipe(
        catchError(ErrorHandler.handleError('configs', [])),
      );
  }

  updateConfigs(configs: UpdateConfigurationDto[]): Promise<unknown> {
    return firstValueFrom(this.http.post<unknown>(apiUrl, configs, httpOptions).pipe(
      catchError(ErrorHandler.handleError<unknown>('add')),
    ));
  }

  requestAllegroAuthorization(): Promise<AllegroDeviceRequestResponse> {
    return firstValueFrom(this.http
      .get<AllegroDeviceRequestResponse>(`${authApiUrl}/request`)
      .pipe(
        catchError(ErrorHandler.handleError('configs', null)),
      ));
  }

  confirmAllegroAuthorization() {
    return this.http
      .get<{valid: boolean}>(`${authApiUrl}/confirm`)
      .pipe(
        catchError(ErrorHandler.handleError('configs', null)),
      );
  }

  getMe(): Observable<AllegroUserDataResponse>{
    return this.http
      .get(`${authApiUrl}/me`)
      .pipe(
        catchError(ErrorHandler.handleError('configs', null)),
      );
  }
}
