import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { UserDto } from '../../../../src/users/dto/user.dto';
import { ErrorHandler } from '../common/error-handler';
import { Router } from '@angular/router';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

const apiUrl = '/api/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private ME_URL = `${apiUrl}/me`;
  private LOGIN_URL = `${apiUrl}/login`;
  private LOGIN_WITH_CODE_URL = `${apiUrl}/loginWithCode`;
  private LOGOUT_URL = `${apiUrl}/logout`;
  private user$: BehaviorSubject<UserDto> = new BehaviorSubject<UserDto>(null);
  public showTopBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showUrlList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient, private router: Router) {
    // this.checkLogin(); TODO remember to enable this
  }

  public signedIn(): Observable<boolean> {
    return this.user$.pipe(
      map(user => {
        return !!user && !!user.active;
      }),
    );
  }

  public signedInUser(): Observable<UserDto> {
    return this.user$;
  }

  checkLogin(): void {
    const request = this.getMe();
    request.subscribe(user => {
      this.user$.next(user);
      this.router.navigate(['/dashboard']).then();
    });
  }

  getMe(): Observable<UserDto> {
    return this.http.get<UserDto>(this.ME_URL).pipe(catchError(() => of(null)));
  }

  login(username: string, password: string): Observable<UserDto> {
    return this.http
      .post<UserDto>(this.LOGIN_URL, { username, password })
      .pipe(
        catchError(() => of(null)),
        tap(user => {
          this.user$.next(user);
          if (!!user) {
            const redirect = user.passwordNeedsChange ? '/me' : '/configuration';
            this.router.navigate([redirect]).then();
          }
        }),
      );
  }

  signOut() {
    const request = this.http.get(this.LOGOUT_URL);
    request.pipe(catchError(ErrorHandler.handleError<UserDto>('logout', null))).subscribe(() => {
      this.user$.next(null);
    });
    return request;
  }

  updateMe(id: any, user: UserDto): Observable<any> {
    const url = `${apiUrl}/me`;
    return this.http.put(url, user, httpOptions).pipe(
      tap(_ => console.log(`updated User id=${id}`)),
      catchError(ErrorHandler.handleError<any>('updateMe')),
    );
  }

  resetPassword(value: any): Observable<any> {
    const url = `${apiUrl}/resetPassword`;
    return this.http
      .post(url, { usernameOrEmail: value }, httpOptions)
      .pipe(catchError(ErrorHandler.handleError<any>('resetPassword')));
  }
}
