import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from '../material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppCommonModule } from '../common/app-common.module';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, MaterialModule, TranslateModule, AppCommonModule, MatRippleModule],
})
export class AuthModule {}
