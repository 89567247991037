<mat-action-list>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex>
    <div *ngIf="data.isDelete">
      <button mat-flat-button color="accent" mat-stroked-button
              (click)="actions.get(SaveBottomSheetAction.DELETED)()">{{'form.remove' | translate}}</button>
    </div>
    <span class="fill-space"></span>
<!--    <div>-->
<!--      <button mat-flat-button mat-stroked-button-->
<!--              (click)="actions.get(SaveBottomSheetAction.CANCELED)()">{{'form.cancel' | translate}}</button>-->
<!--    </div>-->
    <div>
      <button mat-flat-button color="primary" (click)="actions.get(SaveBottomSheetAction.SAVED)()">{{'form.save' | translate}}</button>
    </div>
  </div>
</mat-action-list>

