export enum ConfigurationKey {
  ALLEGRO_CLIENT_KEY = 'allegroClientKey',
  ALLEGRO_CLIENT_SECRET = 'allegroClientSecret',
  ALLEGRO_API_URL = 'allegroApiUrl',
  ALLEGRO_SANDBOX_CLIENT_KEY = 'allegroSandboxClientKey',
  ALLEGRO_SANDBOX_CLIENT_SECRET = 'allegroSandboxClientSecret',
  ALLEGRO_SANDBOX_API_URL = 'allegroSandboxApiUrl',
  ALLEGRO_SANDBOX_ENABLED = 'allegroSandboxEnabled',
  ALLEGRO_AUTH_URL = 'allegroAuthUrl',
  ALLEGRO_SANDBOX_AUTH_URL = 'allegroSandboxAuthUrl',
  ALLEGRO_REDIRECT_URI = 'allegroRedirectUri',
  ALLEGRO_SANDBOX_REDIRECT_URI = 'allegroSandboxRedirectUri',
  ALLEGRO_SANDBOX_REFRESH_TOKEN = 'allegroSandboxRefreshToken',
  ALLEGRO_REFRESH_TOKEN = 'allegroRefreshToken',
  INTERCARS_USER_PATH = 'intercarsUserPath',
  INTERCARS_LOGIN = 'intercarsLogin',
  INTERCARS_PASS = 'intercarsPass',
  INTERCARS_CLIENT_ID = 'intercarsClientId',
  INTERCARS_CLIENT_SECRET = 'intercarsClientSecret',
  INTERCARS_AUTH_URL = 'intercarsAuthUrl',
  INTERCARS_API_URL = 'intercarsApiUrl',
  INTERCARS_HOST = 'intercarsHost',
  INTERCARS_SANDBOX_USER_PATH = 'intercarsSandboxUserPath',
  INTERCARS_SANDBOX_LOGIN = 'intercarsSandboxLogin',
  INTERCARS_SANDBOX_PASS = 'intercarsSandboxPass',
  INTERCARS_SANDBOX_HOST = 'intercarsSandboxHost',
  PRODUCT_INFORMATION_RELOAD_CRON = 'productInformationReloadCron',
  STOCK_RELOAD_CRON = 'stockReloadCron',
  ADDITIONAL_DESCRIPTION = 'additionalDescription',
  ADDITIONAL_DESCRIPTION_ENABLED = 'additionalDescriptionEnabled',
  ALWAYS_RELOAD_AUCTIONS_AFTER_SAVE = 'alwaysReloadAuctionsAfterSave',
  WAREHOUSE_ENABLED_LIST = 'warehouseEnabledList',
  USE_ONLY_PRODUCTS_WITH_IMAGES = 'useOnlyProductsWithImages',
  USE_ONLY_PRODUCTS_WITH_DIMENSIONS = 'useOnlyProductsWithDimensions',
  MINIMUM_STOCK = 'minimumStock',
  AUCTIONS_RELOAD_CRON = 'auctionsReloadCron',
  GROSS_PERCENT = 'grossPercent',
  BATCH_SIZE = 'batchSize',
  CREATE_AUCTIONS_AS_ACTIVE = 'createAuctionsAsActive',
  STOCK_INVENTORY_UPDATE_CRON = 'stockInventoryUpdateCron',
  PRODUCT_DESCRIPTION_UPDATE_CRON = 'productDescriptionUpdateCron',
  BLACKLISTED_PRODUCTS = 'blacklistedProducts',
  SHOULD_UPDATE_PRICE = 'shouldUpdatePrice',
  EXCLUDED_MANUFACTURERS = 'excludedManufacturers',
  MAXIMUM_AUCTION_PRICE = 'maximumAuctionPrice',
  DELIVERIES = 'deliveries',
  MIN_DELIVERY_TIME = 'minDeliveryTime',
}

export enum ConfigurationType {
  APP,
  DETAIL,
}

export enum ConfigurationValueType {
  NUMBER,
  STRING,
  BOOLEAN,
  STRING_LIST,
  TEXTAREA,
  STRING_CRON
}

export const configurations: Map<
  ConfigurationKey,
  {
    keyType: ConfigurationType;
    valueType: ConfigurationValueType;
    restricted: boolean;
  }
> = new Map<
  ConfigurationKey,
  { keyType: ConfigurationType; valueType: ConfigurationValueType; restricted: boolean }
>([
  [
    ConfigurationKey.MAXIMUM_AUCTION_PRICE,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.NUMBER,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.DELIVERIES,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING_LIST,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.MIN_DELIVERY_TIME,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.ALLEGRO_CLIENT_KEY,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.ALLEGRO_CLIENT_SECRET,
    { keyType: ConfigurationType.APP, valueType: ConfigurationValueType.STRING, restricted: true },
  ],
  [
    ConfigurationKey.ALLEGRO_API_URL,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.ALLEGRO_AUTH_URL,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.ALLEGRO_SANDBOX_CLIENT_KEY,
    { keyType: ConfigurationType.APP, valueType: ConfigurationValueType.STRING, restricted: true },
  ],
  [
    ConfigurationKey.ALLEGRO_SANDBOX_CLIENT_SECRET,
    { keyType: ConfigurationType.APP, valueType: ConfigurationValueType.STRING, restricted: true },
  ],
  [
    ConfigurationKey.ALLEGRO_SANDBOX_API_URL,
    { keyType: ConfigurationType.APP, valueType: ConfigurationValueType.STRING, restricted: true },
  ],
  [
    ConfigurationKey.ALLEGRO_SANDBOX_AUTH_URL,
    { keyType: ConfigurationType.APP, valueType: ConfigurationValueType.STRING, restricted: true },
  ],
  [
    ConfigurationKey.ALLEGRO_SANDBOX_ENABLED,
    { keyType: ConfigurationType.APP, valueType: ConfigurationValueType.BOOLEAN, restricted: true },
  ],
  [
    ConfigurationKey.INTERCARS_USER_PATH,
    { keyType: ConfigurationType.APP, valueType: ConfigurationValueType.STRING, restricted: true },
  ],
  [
    ConfigurationKey.INTERCARS_LOGIN,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.INTERCARS_PASS,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.INTERCARS_HOST,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.INTERCARS_CLIENT_ID,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.INTERCARS_CLIENT_SECRET,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.INTERCARS_AUTH_URL,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.INTERCARS_API_URL,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.INTERCARS_SANDBOX_USER_PATH,
    { keyType: ConfigurationType.APP, valueType: ConfigurationValueType.STRING, restricted: true },
  ],
  [
    ConfigurationKey.INTERCARS_SANDBOX_LOGIN,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.INTERCARS_SANDBOX_PASS,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.INTERCARS_SANDBOX_HOST,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.PRODUCT_INFORMATION_RELOAD_CRON,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.STRING_CRON,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.STOCK_INVENTORY_UPDATE_CRON,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.STRING_CRON,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.STOCK_RELOAD_CRON,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.STRING_CRON,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.ADDITIONAL_DESCRIPTION,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.TEXTAREA,
      restricted: false,
    },
  ],
  [
    ConfigurationKey.ADDITIONAL_DESCRIPTION_ENABLED,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.BOOLEAN,
      restricted: false,
    },
  ],
  [
    ConfigurationKey.ALWAYS_RELOAD_AUCTIONS_AFTER_SAVE,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.BOOLEAN,
      restricted: false,
    },
  ],
  [
    ConfigurationKey.WAREHOUSE_ENABLED_LIST,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.USE_ONLY_PRODUCTS_WITH_IMAGES,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.BOOLEAN,
      restricted: false,
    },
  ],
  [
    ConfigurationKey.USE_ONLY_PRODUCTS_WITH_DIMENSIONS,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.BOOLEAN,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.MINIMUM_STOCK,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.NUMBER,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.AUCTIONS_RELOAD_CRON,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.STRING_CRON,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.ALLEGRO_REDIRECT_URI,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.ALLEGRO_SANDBOX_REDIRECT_URI,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.GROSS_PERCENT,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.NUMBER,
      restricted: false,
    },
  ],
  [
    ConfigurationKey.BATCH_SIZE,
    {
      keyType: ConfigurationType.DETAIL,
      valueType: ConfigurationValueType.NUMBER,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.ALLEGRO_REFRESH_TOKEN,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.ALLEGRO_SANDBOX_REFRESH_TOKEN,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.CREATE_AUCTIONS_AS_ACTIVE,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.BOOLEAN,
      restricted: false,
    },
  ],
  [
    ConfigurationKey.PRODUCT_DESCRIPTION_UPDATE_CRON,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.STRING_CRON,
      restricted: true,
    },
  ],
  [
    ConfigurationKey.BLACKLISTED_PRODUCTS,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.TEXTAREA,
      restricted: false,
    },
  ],
  [
    ConfigurationKey.SHOULD_UPDATE_PRICE,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.BOOLEAN,
      restricted: false,
    },
  ],

  [
    ConfigurationKey.EXCLUDED_MANUFACTURERS,
    {
      keyType: ConfigurationType.APP,
      valueType: ConfigurationValueType.TEXTAREA,
      restricted: false,
    },
  ],
]);
