import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PasswordNeedsChangeGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.signedInUser().pipe(
      map(user => {
        const passwordChangeNotNeeded = !!user && !user.passwordNeedsChange;
        if (!passwordChangeNotNeeded) {
          this.router.parseUrl('/me');
        }
        return passwordChangeNotNeeded;
      }),
    );
  }
}
