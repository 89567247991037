import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { BehaviorSubject } from 'rxjs';
import { AllegroUserDataResponse } from '../../../../../src/allegro/allegro-api/allegro-api.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit {
  public authorizeUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public confirmAuthorize$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public meInfo$: BehaviorSubject<AllegroUserDataResponse> = new BehaviorSubject<
    AllegroUserDataResponse
  >(null);
  private meInterval;

  constructor(private readonly configurationService: ConfigurationService) {}

  ngOnInit(): void {
this.getMeInfo();
  }

  private getMeInfo() {
    this.configurationService
      .getMe()
      .pipe(take(1))
      .subscribe(res => this.meInfo$.next(res));
  }

  onAuthorizeAllegro() {
    this.confirmAuthorize$.next('authorize');
  }

  onConnectToAllegro() {
    this.configurationService.requestAllegroAuthorization().then(res => {
      this.authorizeUrl$.next(res.verification_uri_complete);
    });
  }

  onConfirmAuthorized() {
    this.configurationService.confirmAllegroAuthorization().pipe(take(1)).subscribe(res => {
      setTimeout(() => this.getMeInfo(), 3000);
      console.log('confirmed');
    });
  }
}
