import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from '../i18n.service';
import { I18nDto } from '../../../../../src/i18n/dto/i18n.dto';
import { Observable } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetConfig, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  SaveBottomSheetAction,
  SaveBottomSheetComponent
} from '../../common/save-bottom-sheet/save-bottom-sheet.component';
import { MatDialogRef } from '@angular/material/dialog';
import { NameFormDialogComponent } from '../../common/name-form-dialog/name-form-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { SnackService } from '../../common/snack.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

interface FormValue {
  key: string;
  value: string;
  reference: string;
}

@Component({
  selector: 'app-i18n-details',
  templateUrl: './i18n-details.component.html',
  styleUrls: ['./i18n-details.component.scss']
})
export class I18nDetailsComponent implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;
  private id: string;
  public i18nDto$: Observable<I18nDto>;
  public form: UntypedFormGroup;
  public entriesPrepared = false;
  public saveSheet: MatBottomSheetRef<SaveBottomSheetComponent>;
  private readonly bottomSheetConfig = new MatBottomSheetConfig();
  private nameDialogRef: MatDialogRef<NameFormDialogComponent>;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly i18nService: I18nService,
    private readonly cd: ChangeDetectorRef,
    private readonly bottomSheet: MatBottomSheet,
    private readonly translateService: TranslateService,
    private readonly snackService: SnackService,
    private readonly router: Router) {
    this.bottomSheetConfig.panelClass = 'mat-bottom-sheet-container';
    this.bottomSheetConfig.hasBackdrop = false;
    this.bottomSheetConfig.restoreFocus = false;
  }

  ngOnDestroy() {
    if (this.saveSheet) {
      this.saveSheet.dismiss(false);
    }
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;

    this.form = this.formBuilder.group(
      {
        lang: new UntypedFormControl({value: '', disabled: false}, [Validators.required]),
        name: new UntypedFormControl({value: '', disabled: false}, [Validators.required]),
        entries: this.formBuilder.array([]),
      },
    );

    this.i18nService.loadById(this.id).subscribe(dto => {
      this.patchFormValue(dto);
    });

    if (!this.saveSheet) {
      this.bottomSheetConfig.data = {};
      this.saveSheet = this.bottomSheet.open(SaveBottomSheetComponent, this.bottomSheetConfig);
      this.saveSheet.instance.nonClosingAction.subscribe(action => {
        this.processAction(action);
      });
    }

  }

  get name() {
    return this.form.get('name');
  }

  get lang() {
    return this.form.get('lang');
  }

  entries(): UntypedFormArray {
    return this.form.get('entries') as UntypedFormArray;
  }

  // removeEntry = (entry: FormControl) => entry.setValue({...comment.value, deleted: true});
  addEntry = () => this.entries().insert(0, this.newEntry());
  newEntry = (): UntypedFormGroup => this.newEntryWithKey();

  newEntryWithKey(entry: FormValue = null): UntypedFormGroup {
    return this.formBuilder.group({
      key: entry?.key || null,
      value: [entry?.value, Validators.required],
      reference: [entry?.reference]
    });
  }

  private patchFormValue(i18n: I18nDto) {
    this.form.patchValue({name: i18n.name, lang: i18n.lang});
    for (const field in i18n.reference) {
      if (i18n.reference.hasOwnProperty(field)) {
        this.entries().push(this.newEntryWithKey({
          key: field,
          value: i18n.entries[field] || '',
          reference: i18n.reference[field]
        }));
      }
    }
    this.cd.detectChanges();
  }

  save() {
    // console.log(this.form.value);
  }

  private processAction(action: SaveBottomSheetAction) {
    this.form.markAllAsTouched();
    this.cd.detectChanges();
    if (this.form.valid) {
      this.blockUI.start();
      this.i18nService.save(this.id, this.prepareValues(this.form.value))
        .subscribe(() => {
          this.i18nService.translationsNeedReload$.next(true);
          this.snackService.openSuccessSnackBar(
            this.translateService.instant('form.savedSuccessfullyMessage'),
            'OK',
          );
          this.blockUI.stop();
          this.router.navigate(['/i18n']).then();
        });
    } else {
      this.snackService.openErrorSnackBar(
        this.translateService.instant('form.someValuesAreMissing'),
        'OK',
      );
    }
  }

  private prepareValues(value: any) {
    return {
      name: value.name,
      lang: value.lang,
      entries: this.prepareEntries(value.entries)
    };
  }

  private prepareEntries(entries: FormValue[]): { [p: string]: string } {
    const ret: { [p: string]: string } = {};
    entries.forEach(e => {
      ret[e.key] = e.value;
    });
    return ret;
  }

  addKey() {

  }
}
