import { Component, OnInit } from '@angular/core';
import { I18nDto } from '../../../../../src/i18n/dto/i18n.dto';
import { Observable, of } from 'rxjs';
import { I18nService } from '../i18n.service';

@Component({
  selector: 'app-i18n-list',
  templateUrl: './i18n-list.component.html',
  styleUrls: ['./i18n-list.component.scss']
})
export class I18nListComponent implements OnInit {
  langs$: Observable<Array<I18nDto>> = of([]);

  constructor(private readonly i18nService: I18nService) {
    this.langs$ = i18nService.getLangsForEdit();
  }

  ngOnInit(): void {
  }

}
