<mat-tab-group #tabGroup class="dashboard-list margin-15" mat-align-tabs="start">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>settings</mat-icon>
      Ustawienia
    </ng-template>
    <ng-container>
      <div>
        <app-configuration-form></app-configuration-form>
      </div>
    </ng-container>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>link</mat-icon>
      Autoryzacja Allegro
    </ng-template>
    <ng-container>
      <div>
        <div *ngIf="meInfo$ | async as me" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div>
            <mat-icon style="margin-bottom: -8px;" color="warn">link</mat-icon>
          </div>
          <div>Jesteś połączony z Allegro jako: <b>{{ me.firstName }} {{ me.lastName }} ({{ me.email }})</b></div>
        </div>
        <ng-container *ngIf="!(confirmAuthorize$ | async) && !(meInfo$ | async)">
          <mat-icon style="margin-bottom: -8px;" color="accent">link_off</mat-icon> Nie jesteś połączony z Allegro<br><br>
          <a *ngIf="!(authorizeUrl$ | async)" (click)="onConnectToAllegro()" mat-button mat-raised-button color="accent">Połącz z Allegro</a>
          <a *ngIf="authorizeUrl$ | async as authorizeUrl" (click)="onAuthorizeAllegro()" mat-button mat-raised-button color="warn" target="_blank" [attr.href]="authorizeUrl ">Autoryzuj</a>
        </ng-container>
        <a *ngIf="confirmAuthorize$ | async" (click)="onConfirmAuthorized()" mat-button mat-raised-button color="primary">Potwierdź autoryzację Allegro</a>
      </div>
    </ng-container>
  </mat-tab>

</mat-tab-group>
