import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

export enum SaveBottomSheetAction {
  SAVED,
  CANCELED,
  DELETED,
}

@Component({
  selector: 'app-save-bottom-sheet',
  templateUrl: './save-bottom-sheet.component.html',
  styleUrls: ['./save-bottom-sheet.component.scss'],
})
export class SaveBottomSheetComponent implements OnInit {
  @Input() activeActions: Array<SaveBottomSheetAction> = [];
  public nonClosingAction: EventEmitter<SaveBottomSheetAction> = new EventEmitter<
    SaveBottomSheetAction
  >();
  public SaveBottomSheetAction = SaveBottomSheetAction;
  public actions = new Map<SaveBottomSheetAction, Function>([
    [SaveBottomSheetAction.SAVED, () => this.nonClosingAction.emit(SaveBottomSheetAction.SAVED)],
    // [
    //   SaveBottomSheetAction.CANCELED,
    //   () => this.bottomSheetRef.dismiss(SaveBottomSheetAction.CANCELED),
    // ],
    [
      SaveBottomSheetAction.DELETED,
      () => this.nonClosingAction.emit(SaveBottomSheetAction.DELETED),
    ],
  ]);

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

  ngOnInit() {}
}
