
export enum ActionWebRequestType {
  PUBLISH, UNPUBLISH, REMOVE_DRAFTS

}

interface ActionWebRequestParams {
}
export class CreateActionWebRequestDto {
  action: ActionWebRequestType;
  params?: ActionWebRequestParams;
}
