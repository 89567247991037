<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center" fxFlexFill class="dashboard-list">
  <mat-form-field>
    <mat-label>{{'generic.searchPlaceholder' | translate}}</mat-label>
    <input autocomplete="off" matInput [(ngModel)]="filter" (keyup)="applyFilter($event)" placeholder="{{'generic.searchPlaceholder' | translate}}">
  </mat-form-field>
  <div class="fill-space"></div>
<!--  <div fxLayout="row" fxLayoutGap="10px">
    <button (click)="onCreate()" mat-button mat-raised-button color="primary">
      {{'form.create' | translate}}</button>
  </div>-->
</div>
<div fxLayout="column" fxLayoutGap="10px">
  <mat-progress-bar [style.display]="isLoadingResults ? 'block' : 'none'" mode="query"></mat-progress-bar>
  <div class="mat-elevation-z2">
    <table mat-table [dataSource]="dataSource" fxFill>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{'i18n.name' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="lang">
        <th mat-header-cell *matHeaderCellDef>{{'i18n.lang' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.lang}} </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell  fxHide.lt-lg *matHeaderCellDef> {{'i18n.active' | translate}}</th>
        <td mat-cell  fxHide.lt-lg *matCellDef="let element"> <app-active-badge [active]="element.active"></app-active-badge> </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th fxHide.lt-lg mat-header-cell *matHeaderCellDef style="text-align: end"> {{'dashboard.actions' | translate}}</th>
        <td  fxHide.lt-lg mat-cell *matCellDef="let element">
          <div style="text-align: end">
            <button color="primary" (click)="onEdit(element._id)"
                    mat-button mat-icon-button>
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="30" [pageSizeOptions]="[5, 10, 20, 30, 50, 100]" showFirstLastButtons></mat-paginator>
    <br><br><br><br><br>
  </div>
</div>
