import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-active-badge',
  templateUrl: './active-badge.component.html',
  styleUrls: ['./active-badge.component.scss'],
})
export class ActiveBadgeComponent implements OnInit {
  @Input() active: boolean;
  badge: string;
  badges = new Map<boolean, string>([
    [false, 'highlight_off'],
    [true, 'check_circle'],
  ]);

  constructor() {}

  ngOnInit(): void {
    this.badge = this.badges.get(this.active);
  }
}
