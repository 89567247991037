import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { I18nDto } from '../../../../src/i18n/dto/i18n.dto';
import { BehaviorSubject, Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateLoaderService implements TranslateLoader {

  private langs: BehaviorSubject<I18nDto[]> = new BehaviorSubject<I18nDto[]>(null);

  // private initiallyRetrieved = false;

  constructor(
    private readonly i18nService: I18nService
  ) {
    this.load();
    this.i18nService.translationsNeedReload$.subscribe(() => this.load());
  }

  getTranslation(lang: string): Observable<{ [p: string]: string }> {
    return new Observable((observer) => {
      this.langs.pipe(
        skipWhile(value => value === null),
      ).subscribe(val => {
        let found = val.find(l => l.lang.toLowerCase() === lang.toLowerCase());
        found = found || val.find(l => l.lang.toLowerCase() === 'en');
        observer.next(found.entries);
      });
    });
  }

  private load() {
    this.i18nService
      .load()
      .subscribe(languages => {
        this.langs.next(languages);
        // this.translateService.reloadLang(this.translateService.currentLang);
        // this.langs.complete();
      });
  }
}
