import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { AuthModule } from './auth/auth.module';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthInterceptor } from './auth/auth.interceptor';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { NgxTranslateRoutesModule } from 'ngx-translate-routes';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { AppCommonModule } from './common/app-common.module';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { HttpErrorInterceptor } from './auth/http-error.interceptor';
import { SnackService } from './common/snack.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { TranslateLoaderService } from './i18n/translate-loader.service';
import { I18nModule } from './i18n/i18n.module';
import { MissingI18nHandler } from './i18n/missing-i18n-handler';
import { I18nService } from './i18n/i18n.service';
import { BlockUIModule } from 'ng-block-ui';
import { ConfigurationModule } from './configuration/configuration.module';
import { CronToHumanPipe } from './cron-to-human.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

const appearance: MatFormFieldDefaultOptions = {
  // appearance: "outline",
  floatLabel: 'always',
};

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};

const config: SocketIoConfig = {
  url: 'ws://192.168.68.111:3000',
  options: { path: '/ws/events', transports: ['websocket'], upgrade: false },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BlockUIModule.forRoot({
      message: '',
    }),
    CommonModule,
    AppCommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    AuthModule,
    MatSidenavModule,
    // MatMarkdownEditorModule.forRoot(),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: TranslateLoaderService },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingI18nHandler,
        deps: [I18nService],
      },
      /*loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },*/
    }),
    NgxTranslateRoutesModule.forRoot(),
    PerfectScrollbarModule,
    SocketIoModule.forRoot(config),
    I18nModule,
    ConfigurationModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { autoFocus: false, hasBackdrop: true } },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    { provide: MAT_DATE_LOCALE, useValue: localStorage.getItem('lang') || 'fr' },
    // { provide: NgxMatDateAdapter, useClass: NgxMatNativeDateAdapter },
    // { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [SnackService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
