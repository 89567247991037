import { Component, OnInit } from '@angular/core';
import { ConfigurationActionService } from './configuration-action.service';
import { ActionWebRequestType } from '../../../../../src/configuration/action/dto/create-action-web-request.dto';

@Component({
  selector: 'app-configuration-action',
  templateUrl: './configuration-action.component.html',
  styleUrls: ['./configuration-action.component.scss'],
})
export class ConfigurationActionComponent implements OnInit {
  constructor(private readonly configurationActionService: ConfigurationActionService) {}

  ngOnInit(): void {}

  deactivateAuctions() {}

  activateAuctions() {}

  updatePrices() {}

  remofeDraftAuctions() {
    this.configurationActionService
      .requestAction({ action: ActionWebRequestType.REMOVE_DRAFTS })
      .subscribe();
  }
}
