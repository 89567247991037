<h2 mat-dialog-title>{{data.title | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="form" autocomplete="off" fxLayout="column" fxLayoutGap="10px">
    <mat-form-field fxFlex="100%">
      <mat-label>{{'form.name' | translate}}</mat-label>
      <input matInput placeholder="{{'form.name' | translate}}" formControlName="name"
             autocomplete="off">
      <mat-hint>{{'form.nameDescription' | translate}}</mat-hint>
      <mat-error *ngIf="name.hasError('required')">{{'form.required' | translate}}</mat-error>
      <mat-error *ngIf="name.hasError('alreadyUsed')">{{'form.alreadyUsed' | translate}}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button mat-button mat-dialog-close>{{'form.cancel' | translate}}</button>
  <button mat-button color="primary" mat-flat-button (click)="save()">{{'form.save' | translate}}</button>
</mat-dialog-actions>
