import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nListComponent } from './i18n-list/i18n-list.component';
import { I18nComponent } from './i18n/i18n.component';
import { I18nListTableComponent } from './i18n-list-table/i18n-list-table.component';
import { MaterialModule } from '../material/material.module';
import { AppCommonModule } from '../common/app-common.module';
import { TranslateModule } from '@ngx-translate/core';
import { I18nDetailsComponent } from './i18n-details/i18n-details.component';


@NgModule({
  declarations: [
    I18nListTableComponent,
    I18nListComponent,
    I18nComponent,
    I18nDetailsComponent
  ],
  exports: [I18nListComponent],
  imports: [
    CommonModule,
    MaterialModule,
    AppCommonModule,
    TranslateModule
  ]
})
export class I18nModule {
}
