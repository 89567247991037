import { Pipe, PipeTransform } from '@angular/core';
import { FileType, UserFileDto } from '../../../../src/users/dto/user-file.dto';

export interface FileTypeWithPublic{
  types: FileType[];
  public: boolean;
}

@Pipe({
  name: 'filterFileTypeWithPublic',
})
export class FileTypeWithPublicPipe implements PipeTransform {
  transform(list: UserFileDto[], fileTypeWithPublic: FileTypeWithPublic) {
    return !list ? null : list.filter(item => fileTypeWithPublic.types.includes(item.type) && item.public === fileTypeWithPublic.public);
  }
}
