import { User } from '../schemas/user.schema';
import { ObjectId } from 'mongoose';

export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPERUSER = 'SUPERUSER',
}

export class UserDto {
  readonly _id: ObjectId;
  readonly createdBy: User;
  readonly createdAt: Date;
  readonly parent: User;
  readonly username: String;
  readonly fullname: String;
  readonly email: String;
  readonly password?: String;
  readonly active?: Boolean;
  readonly role?: UserRole;
  readonly sendEmail?: Boolean;
  readonly passwordNeedsChange: Boolean;
}
