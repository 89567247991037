import { Pipe, PipeTransform } from '@angular/core';
import { FileType, UserFileDto } from '../../../../src/users/dto/user-file.dto';
@Pipe({
  name: 'filterFileType',
})
export class FileTypePipe implements PipeTransform {
  transform(list: UserFileDto[], allowedTypes: FileType[]) {
    return !list ? null : list.filter(item => allowedTypes.includes(item.type));
  }
}
