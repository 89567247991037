import { Component, Input, OnInit } from '@angular/core';
import { UserRole } from '../../../../../src/users/dto/user.dto';

@Component({
  selector: 'app-role-badge',
  templateUrl: './role-badge.component.html',
  styleUrls: ['./role-badge.component.scss'],
})
export class RoleBadgeComponent implements OnInit {
  @Input() role: UserRole;
  UserRole = UserRole;
  badge: string;
  badges = new Map<UserRole, string>([
    [UserRole.SUPERUSER, 'admin_panel_settings'],
    [UserRole.ADMIN, 'account_circle'],
    [UserRole.USER, 'face'],
  ]);

  constructor() {}

  ngOnInit(): void {
    this.badge = this.badges.get(this.role);
  }
}
