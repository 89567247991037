import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  ConfigurationKey,
  configurations,
  ConfigurationValueType,
} from '../../../../../src/configuration/dto/configuration.dto';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import { UpdateConfigurationDto } from '../../../../../src/configuration/dto/update-configuration.dto';
import { ConfigurationService } from '../configuration.service';
import { SnackService } from '../../common/snack.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-configuration-form',
  templateUrl: './configuration-form.component.html',
  styleUrls: ['./configuration-form.component.scss'],
})
export class ConfigurationFormComponent implements OnInit {
  public configurations: Map<ConfigurationKey, {valueType: ConfigurationValueType, restricted: boolean}> = configurations;
  public form: UntypedFormGroup;
  public ConfigurationValueType = ConfigurationValueType;
  public configsLoaded: boolean = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly configurationService: ConfigurationService,
    private readonly cd: ChangeDetectorRef,
    private readonly snackService: SnackService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      configs: this.formBuilder.array([]),
    });
    this.loadAndapplyConfigs();
  }

  configs() {
    return this.form.get('configs') as UntypedFormArray;
  }

  private loadAndapplyConfigs() {
    this.configurationService.getConfigs().pipe(take(1)).subscribe(
      (configs: UpdateConfigurationDto[]) => {
        configs.forEach(config => {
          this.configs().push(this.newConfigFormGroup(config));
        });
        this.configsLoaded = true;
        this.cd.detectChanges();
      },
    );
  }

  newConfigFormGroup(config: UpdateConfigurationDto = null): UntypedFormGroup {
    return this.formBuilder.group({
      key: new UntypedFormControl(config.key),
      value: new UntypedFormControl({value: config.value, disabled: this.configurations.get(config.key).restricted}, [Validators.required]),
    });
  }

  submit($event: SubmitEvent) {
    if (this.form.valid) {
      this.configurationService
        .updateConfigs(
          this.configs()
            .controls.filter(ctrl => ctrl.dirty)
            .map(ctrl => ctrl.value),
        )
        .then(() => this.snackService.openSuccessSnackBar('Konfiguracja zapisana poprawnie', 'OK'))
        .catch(() =>
          this.snackService.openErrorSnackBar(
            'Nie udało się zapisać konfiguracji, spróbuj ponownie',
            'OK',
          ),
        );
    } else {
      this.snackService.openErrorSnackBar('Podano nieprawidłowe wartości, proszę poprawić', 'OK');
    }
  }
}
