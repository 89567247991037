import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackService } from '../common/snack.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private snackService: SnackService, private translateService: TranslateService) {
  }

  private handleHttpError(response: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (![401, 403].includes(response.status)) {
      this.snackService.openErrorSnackBar(
        response.error.message ||
        response.error.error.message ||
        this.translateService?.instant('error.unknownServerError'),
        'Ok',
      );
      return of(response.message); // or EMPTY may be appropriate here
    }
    return throwError(response);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(e => this.handleHttpError(e)));
    // here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
  }
}
