import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { alreadyUsedValidator } from '../validators/already-used.validator';

export interface NumberFormDialogData {
  value: number;
  title: string;
}

@Component({
  selector: 'app-number-form-dialog',
  templateUrl: './number-form-dialog.component.html',
  styleUrls: ['./number-form-dialog.component.scss'],
})
export class NumberFormDialogComponent implements OnInit {
  title = 'missing window title';
  form: UntypedFormGroup;
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NumberFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NumberFormDialogData,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      number: new UntypedFormControl(this.data.value || 100, [
        Validators.required, Validators.min(1)
      ]),
    });
  }

  get number() {
    return this.form.get('number');
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
