<!--<block-ui>-->
<div style="height: 100vh; width: 100vw; overflow-y: hidden">
  <mat-toolbar fxLayoutAlign="center center" [fxShow]="(signedIn$ | async) && (authService.showTopBar$ | async)"
               color="primary" class="app-toolbar mat-elevation-z24 toolbar">
    <ng-container *ngIf="signedInUser$ | async as user">
      <span> {{title}}</span>
      <span class="fill-space"></span>
      <div fxShow="true" fxHide.lt-md="true">
        <!--<a routerLink="/user" mat-button [fxShow]="user.role === UserRole.SUPERUSER">
          <mat-icon>supervised_user_circle</mat-icon>
          {{'link.users' | translate}}</a>
        <a routerLink="/me" mat-button>
          <mat-icon>account_circle</mat-icon>
          {{'link.myAccount' | translate}}</a>-->
        <a (click)="logout()" mat-button>
          <mat-icon>exit_to_app</mat-icon>
          {{'link.signout' | translate}} [ {{user.username}} ]</a>
      </div>

      <div>
       <!-- <app-lang-selector></app-lang-selector>-->
      </div>

      <div fxShow="true" fxHide.gt-sm="true">
        <a (click)="sidenav.toggle()" mat-button>
          <mat-icon>menu</mat-icon>
        </a>
      </div>
    </ng-container>
  </mat-toolbar>
  <mat-sidenav-container fxFlexFill class="example-container">
    <mat-sidenav *ngIf="signedIn$ | async" #sidenav fxLayout="column">
      <div>
        <app-lang-selector></app-lang-selector>
      </div>

      <div fxLayout="column">
        <!--<a routerLink="/dashboard" mat-button>
          <mat-icon>format_shapes</mat-icon>
          {{'link.dashboards' | translate}}</a>
        <a routerLink="/user" mat-button>
          <mat-icon>admin_panel_settings</mat-icon>
          {{'link.users' | translate}}</a>-->
        <!--<a routerLink="/me" mat-button>
          <mat-icon>account_circle</mat-icon>
          {{'link.myAccount' | translate}}</a>-->
        <a (click)="logout()" mat-button>
          <mat-icon>exit_to_app</mat-icon>
          {{'link.signout' | translate}} [ {{(signedInUser$ | async).username}} ]</a>
      </div>
    </mat-sidenav>

    <mat-sidenav-content fxFlexFill class="main-content">

      <div class="container">
        <router-outlet class="router-container"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<!--</block-ui>-->
