import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackService {
  constructor(private _snackBar: MatSnackBar) {}

  openErrorSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      verticalPosition: 'top',
      panelClass: 'snack-error',
      duration: 10000,
    });
  }

  openSuccessSnackBar(message: string, action: string, duration = 2000) {
    this._snackBar.open(message, action, {
      verticalPosition: 'top',
      panelClass: 'snack-success',
      duration: duration,
    });
  }
}
