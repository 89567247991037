import { NgModule } from '@angular/core';
import { RoleBadgeComponent } from './role-badge/role-badge.component';
import { MaterialModule } from '../material/material.module';
import { ActiveBadgeComponent } from './active-badge/active-badge.component';
import { NotDeletedPipe } from './not-deleted.pipe';
import { SaveBottomSheetComponent } from './save-bottom-sheet/save-bottom-sheet.component';
import { TranslateModule } from '@ngx-translate/core';
import { NameFormDialogComponent } from './name-form-dialog/name-form-dialog.component';
import { NSDraggableDirective } from './nsdraggable.directive';
import { DisableToggleDirective } from './disable-toggle.directive';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';
import { RedirectToLoginComponent } from './redirect-to-login/redirect-to-login.component';
import { FileTypePipe } from './file-type.pipe';
import { FileTypeWithPublicPipe } from './file-type-with-public.pipe';
import { NumberFormDialogComponent } from './number-form-dialog/number-form-dialog.component';
import { CronToHumanPipe } from '../cron-to-human.pipe';

@NgModule({
  declarations: [
    RoleBadgeComponent,
    ActiveBadgeComponent,
    NotDeletedPipe,
    SaveBottomSheetComponent,
    NameFormDialogComponent,
    NumberFormDialogComponent,
    NSDraggableDirective,
    DisableToggleDirective,
    LangSelectorComponent,
    RedirectToLoginComponent,
    FileTypePipe,
    FileTypeWithPublicPipe,
    CronToHumanPipe
  ],
  imports: [MaterialModule, TranslateModule],
  exports: [
    RoleBadgeComponent,
    ActiveBadgeComponent,
    NotDeletedPipe,
    SaveBottomSheetComponent,
    NSDraggableDirective,
    DisableToggleDirective,
    LangSelectorComponent,
    FileTypePipe,
    FileTypeWithPublicPipe,
    CronToHumanPipe
  ],
})
export class AppCommonModule {
}
