import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-redirect-to-login',
  templateUrl: './redirect-to-login.component.html',
  styleUrls: ['./redirect-to-login.component.scss'],
})
export class RedirectToLoginComponent implements OnInit {
  constructor(private readonly router: Router, private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.authService.signOut().subscribe(() => {
      console.log('redirecting to login page');
      this.router.navigate(['/login']).then();
    });
  }
}
