import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { I18nDto, I18nMissingKey } from '../../../../src/i18n/dto/i18n.dto';
import { Observable, Subject } from 'rxjs';
import { MissingTranslationHandlerParams } from '@ngx-translate/core';


const url = 'api/i18n';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  public translationsNeedReload$: Subject<any> = new Subject();

  constructor(private readonly http: HttpClient) {
  }

  getLangsForEdit(): Observable<I18nDto[]> {
    return this.load();
  }

  public load(): Observable<I18nDto[]> {
    return this.http
      .get<I18nDto[]>(`${url}/langs`);
  }


  public loadById(id: string): Observable<I18nDto> {
    return this.http
      .get<I18nDto>(`${url}/langs/${id}`);
  }

  public updateById(dto: I18nDto): Observable<I18nDto> {
    return this.http
      .post<I18nDto>(`${url}/langs/${dto._id}`, dto);
  }

  save(id, vals: { entries: { [p: string]: string }; name: any; lang: any }) {
    return this.http
      .post<I18nDto>(`${url}/langs/${id}`, vals);
  }

  addMissingKey(params: MissingTranslationHandlerParams) {
    return this.http
      .post<I18nMissingKey>(`${url}/add-missing-key`, {key: params.key});
  }
}
