import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { I18nDto } from '../../../../../src/i18n/dto/i18n.dto';

@Component({
  selector: 'app-i18n-list-table',
  templateUrl: './i18n-list-table.component.html',
  styleUrls: ['./i18n-list-table.component.scss']
})
export class I18nListTableComponent implements OnInit, AfterViewInit {
  @Input() public langs$: Observable<Array<I18nDto>>;
  @Output() public createListener: EventEmitter<any> = new EventEmitter<any>();
  @Output() public editListener: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cloneListener: EventEmitter<any> = new EventEmitter<any>();
  displayedColumns = ['name', 'lang', 'active', 'actions'];
  data: Array<I18nDto> = [];
  dataSource = new MatTableDataSource<I18nDto>(this.data);
  isLoadingResults = false;
  private subject: Subject<string> = new Subject();
  filter: string = localStorage.getItem('i18n-filter') || '';
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private router: Router) {
  }

  applyFilter(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.applyFilterByValue(value);
  }

  load(filterString: string, showArchive = false) {
    this.langs$.subscribe(langs => {
      this.dataSource.data = langs;
      this.isLoadingResults = false;
    });
  }

  ngOnInit(): void {
    this.load(this.filter);
    this.applyFilterByValue(this.filter);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    setTimeout(() => {
      // this.paginator.pageSize = 100;
    });
  }

  private applyFilterByValue(value: string) {
    this.subject.next(value);
    localStorage.setItem('i18n-filter', value);
    this.dataSource.filter = value.trim().toLowerCase();
  }

  onEdit(id) {
    if (this.editListener.observers.length === 0) {
      this.router.navigate(['i18n', id, 'edit']).then();
    } else {
      this.editListener.emit(id);
    }
  }

  onClone(id: any) {
    this.cloneListener.emit();
  }

}
