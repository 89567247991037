import { Pipe, PipeTransform } from '@angular/core';
import cronstrue from 'cronstrue';

@Pipe({
  name: 'cronToHuman',
})
export class CronToHumanPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    }
    console.log(value);
    return cronstrue.toString(value as string);
  }
}
