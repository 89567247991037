import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as uuid from 'uuid';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { SnackService } from '../../common/snack.service';
import { TranslateService } from '@ngx-translate/core';
import { TvSetDto } from '../../../../../src/users/dto/tv-set.dto';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

export enum LoginMode {
  FROM_TV, FROM_COMPUTER
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  public username: string;
  public password: string;
  showSpinner: boolean;
  showError = false;
  formGroup: UntypedFormGroup;
  usernameId$ = of(this.genId('username-'));
  passwordId$ = of(this.genId('password-'));
  loginMode: LoginMode;
  LoginMode = LoginMode;

  constructor(
    private readonly authService: AuthService,
    private readonly fb: UntypedFormBuilder,
    private readonly snackService: SnackService,
    private readonly translateService: TranslateService,
    private readonly titleService: Title,
  ) {

  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      username: ['', Validators.required],
      password: [''],
    });
    this.translateService.get('title.login').subscribe(title => {
      this.titleService.setTitle(`${title} | InterCars 2 Allegro`);
    });
    this.authService
      .signedIn()
      .pipe(take(1))
      .subscribe(user => {
        if (!!user) {
          this.authService.signOut();
        }
      });
  }

  ngAfterViewInit(): void {
    /*setTimeout(() => {
      this.authService.showUrlList$.next(true);
    }, 100);*/
  }


  login() {
    if (this.formGroup.valid) {
      this.showSpinner = true;
      this.authService
        .login(this.formGroup.get('username').value, this.formGroup.get('password').value)
        .subscribe(signedIn => {
          this.showError = !signedIn;
          this.showSpinner = false;
        });
    }
  }

  genId(prefix: string) {
    return `${prefix}-${uuid.v4()}`;
  }

  resetPassword() {
    if (this.formGroup.valid) {
      this.authService.resetPassword(this.formGroup.get('username').value).subscribe(signedIn => {
        this.snackService.openSuccessSnackBar(
          this.translateService.instant('user.passwordResetSuccessfully'),
          'OK',
          10000,
        );
        this.showSpinner = false;
      });
    }
  }

  loginFromComputer() {
    this.loginMode = LoginMode.FROM_COMPUTER;
  }


}
