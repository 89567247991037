import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserDto, UserRole } from '../../../src/users/dto/user.dto';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'InterCars 2 Allegro v1.0';

  public signedIn$: Observable<boolean>;
  public signedInUser$: Observable<UserDto>;
  @ViewChild('sidenav') sidenav: MatSidenav;
  UserRole = UserRole;
  private langs = ['fr', 'pl', 'en'];

  constructor(
    public authService: AuthService,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.signedIn$ = authService.signedIn();
    this.signedInUser$ = authService.signedInUser();
    translate.addLangs(this.langs);
    translate.use(localStorage.getItem('lang') || 'en');
  }

  logout() {
    this.authService.signOut().subscribe(() => this.router.navigate(['/login']));
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      // close sidenav on routing
      if (this.sidenav) {
        this.sidenav.close().then();
      }
    });
  }
}
