<mat-tab-group mat-align-tabs="start" class="margin-15">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>view_quilt</mat-icon>
      {{'i18n.mainTab' | translate}}
    </ng-template>
    <form [formGroup]="form" (ngSubmit)="save()" autocomplete="off">
      <div fxLayout="column" fxLayoutGap="20px" fxLayout.lt-lg="column">
        <div fxLayout="column" fxFlex="100%" fxFlex.lt-lg="100%" style="padding-bottom: 10px;">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>
                <mat-icon>view_quilt</mat-icon>
                {{'form.mainInfo' | translate}}</mat-card-title>
              <!--<mat-spinner [diameter]="20" [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>-->
            </mat-card-header>
            <mat-card-content style="margin-bottom: 20px">

              <div fxLayout="row" class="form" fxLayoutGap="10px">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxFlex="75%">
                  <mat-form-field fxFlex.gt-sm="100%">
                    <mat-label>{{'form.name' | translate}}</mat-label>
                    <input readonly matInput placeholder="{{'form.name' | translate}}" formControlName="name"
                           autocomplete="off">
                    <mat-hint>{{'form.nameDescription' | translate}}</mat-hint>
                    <mat-error *ngIf="name.invalid">{{'form.required' | translate}}</mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxFlex="25%">
                  <mat-form-field fxFlex.gt-sm="100%">
                    <mat-label>{{'form.lang' | translate}}</mat-label>
                    <input readonly matInput placeholder="{{'form.lang' | translate}}" formControlName="lang"
                           autocomplete="off">
                    <mat-hint>{{'form.langDescription' | translate}}</mat-hint>
                    <mat-error *ngIf="lang.invalid">{{'form.required' | translate}}</mat-error>
                  </mat-form-field>
                </div>

              </div>

            </mat-card-content>
          </mat-card>
        </div>
        <div fxLayout="column" fxFlex="100%" fxFlex.lt-lg="100%" style="padding-bottom: 10px;">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>
                <mat-icon>description</mat-icon>
                {{'form.entries' | translate}}</mat-card-title>
              <span class="fill-space"></span>
              <a mat-icon-button (click)="addKey()">
                <mat-icon>add</mat-icon>
              </a>
            </mat-card-header>
            <mat-card-content>
              <div formArrayName="entries" fxLayout="column">
                <div *ngFor="let entry of entries().controls; let entryIndex=index" fxFlex.gt-sm="50%">
                  <div [formGroupName]="entryIndex" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxFlex>
                      <mat-form-field fxFlex.gt-sm="100%">
                        <mat-label>{{entry.value.key}}</mat-label>
                        <input matInput formControlName="value"
                               autocomplete="off">
<!--                        <mat-hint>{{entry.value.reference}}</mat-hint>-->
                        <!--                        <mat-error
                                                  *ngIf="entry.get('value')?.errors?.required">{{'form.required' | translate}}</mat-error>-->
                      </mat-form-field>
                    </div>
                    <div fxFlex="50%" class="reference-label">{{entry.value.reference}}</div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </form>
    <br><br><br><br><br><br><br><br><br>
  </mat-tab>
</mat-tab-group>
