<h2 mat-dialog-title>{{data.title | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="form" autocomplete="off" fxLayout="column" fxLayoutGap="10px">
    <mat-form-field fxFlex="100%">
      <mat-label>{{'form.numberPixels' | translate}}</mat-label>
      <input matInput type="number" placeholder="{{'form.number' | translate}}" formControlName="number"
             autocomplete="off">
      <mat-hint>{{'form.numberPixelsDescription' | translate}}</mat-hint>
      <mat-error *ngIf="number.hasError('required')">{{'form.required' | translate}}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button mat-button mat-dialog-close>{{'form.cancel' | translate}}</button>
  <button mat-button color="primary" mat-flat-button (click)="save()">{{'form.save' | translate}}</button>
</mat-dialog-actions>
