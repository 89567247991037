import { AbstractControl, ValidatorFn } from '@angular/forms';

export function alreadyUsedValidator(usedValues: string[] = []): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control?.value && usedValues &&
      usedValues.map((val) => val?.toLowerCase()).includes(control.value.toLowerCase())
    ) {
      return { alreadyUsed: true };
    }
    return null;
  };
}
