<form [formGroup]="form" (submit)="submit($event)" autocomplete="off" fxLayout="row" fxLayoutGap="10px" fxFlex="100%">
  <div formArrayName="configs" fxLayout="row wrap" fxLayoutGap="10px" fxFlex="100%">
    <div *ngFor="let config of configs().controls; let configIndex=index" fxFlex="49%">
      <mat-card class="mat-elevation-z2" fxFlex="100%" fxLayoutGap="10px">
        <mat-card-content>
          <div [formGroupName]="configIndex" fxLayout="column"
               fxLayoutGap="10px">
            <div fxFlex="100%">
              <div fxLayout="row" fxLayoutGap="10px" fxFlex="100%">
                <div fxFlex="30%" fxLayoutAlign="start center">{{ 'form.' + config.value['key'] | translate }}</div>

                <div fxFlex="70%">

                  <ng-container *ngIf="configurations.get(config.value['key']).valueType === ConfigurationValueType.STRING_CRON">
                    <mat-form-field fxFlex.gt-sm="100%">
                      <mat-label>{{ 'form.' + config.value['key'] + 'Hint' | translate }}</mat-label>
                      <input matInput placeholder="..." formControlName="value">
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="configurations.get(config.value['key']).valueType === ConfigurationValueType.STRING">
                    <mat-form-field fxFlex.gt-sm="100%">
                      <mat-label>{{ 'form.' + config.value['key'] + 'Hint' | translate }}</mat-label>
                      <input matInput placeholder="..." formControlName="value">
                      <!--<mat-hint>{{'form.' + config.value['key'] + 'Hint' | translate}}</mat-hint>-->
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="configurations.get(config.value['key']).valueType === ConfigurationValueType.TEXTAREA">
                    <mat-form-field fxFlex.gt-sm="100%">
                      <mat-label>{{ 'form.' + config.value['key'] + 'Hint' | translate }}</mat-label>
                      <textarea cdkTextareaAutosize matInput formControlName="value"></textarea>
                      <!--<mat-hint>{{'form.' + config.value['key'] + 'Hint' | translate}}</mat-hint>-->
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="configurations.get(config.value['key']).valueType === ConfigurationValueType.NUMBER">
                    <mat-form-field fxFlex.gt-sm="100%">
                      <mat-label>{{ 'form.' + config.value['key'] + 'Hint' | translate }}</mat-label>
                      <input type="number" matInput formControlName="value">
                      <!--<mat-hint>{{'form.' + config.value['key'] + 'Hint' | translate}}</mat-hint>-->
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="configurations.get(config.value['key']).valueType === ConfigurationValueType.BOOLEAN">
                    <mat-checkbox color="primary"
                                  formControlName="value"><!--<span *ngIf="!config.value['value']">UWAGA: Przy wyłączonej tej opcji aplikacja działa w trybie produkcyjnym</span>--></mat-checkbox>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <mat-card class="mat-elevation-z2" fxFlex="100%">
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button type="submit" mat-button mat-raised-button color="primary">Zapisz</button>
        </div>
      </mat-card-content>
    </mat-card>
    <br> <br> <br> <br>
  </div>
</form>
