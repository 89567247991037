import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ConfigurationService } from "./configuration.service";
import { ConfigurationFormComponent } from './configuration-form/configuration-form.component';
import { MaterialModule } from "../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRippleModule } from "@angular/material/core";
import { SaleConfigurationFormComponent } from './sale-configuration-form/sale-configuration-form.component';
import { AppModule } from '../app.module';
import { AppCommonModule } from '../common/app-common.module';
import { ConfigurationActionComponent } from './configuration-action/configuration-action.component';

@NgModule({
  declarations: [
    ConfigurationComponent,
    ConfigurationFormComponent,
    SaleConfigurationFormComponent,
    ConfigurationActionComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    MaterialModule,
    TranslateModule,
    MatCheckboxModule,
    MatRippleModule,
  ],
  providers: [ConfigurationService],
})
export class ConfigurationModule {}
