<div class="lang-selector">
<mat-form-field>
  <mat-label>{{'lang.select' | translate}}</mat-label>
<mat-select [(ngModel)]="lang" (ngModelChange)="setLang($event)">
  <mat-option value="fr">FR</mat-option>
  <mat-option value="en">EN</mat-option>
  <mat-option value="pl">PL</mat-option>
</mat-select>
</mat-form-field>
</div>
