import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
})
export class LangSelectorComponent implements OnInit {
  private static LANG = 'lang';
  private static LANG_CODE = 'langCode';
  public lang: string = localStorage.getItem(LangSelectorComponent.LANG) || 'en';
  private langs = ['fr', 'pl', 'en'];

  locales: Map<string, string> = new Map([
    ['pl', 'pl-PL'],
    ['en', 'en-US'],
    ['fr', 'fr-FR'],
  ]);

  constructor(private readonly translate: TranslateService, private adapter: DateAdapter<Date>) {}

  setLang(l: string) {
    localStorage.setItem(LangSelectorComponent.LANG, l);
    this.translate.use(localStorage.getItem(LangSelectorComponent.LANG));
    localStorage.setItem(LangSelectorComponent.LANG_CODE, this.locales.get(l));
    this.adapter.setLocale(l);
    console.log('this.adapter.setLocale(lang)', l);
  }

  ngOnInit(): void {
    const browserLang = this.translate.getBrowserLang();
    if (!localStorage.getItem('lang')) {
      this.langs.forEach(lang => {
        if (browserLang.match(new RegExp(lang, 'g'))) {
          localStorage.setItem(LangSelectorComponent.LANG, lang);
          localStorage.setItem(LangSelectorComponent.LANG_CODE, this.locales.get(lang));
          this.lang = lang;
          this.translate.use(lang);
          this.adapter.setLocale(lang);
          console.log('this.adapter.setLocale(lang)', lang);
        }
      });
    }
  }
}
