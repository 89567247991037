import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './common/auth.guard';
import { PasswordNeedsChangeGuard } from './common/password-needs-change.guard';
import { I18nListComponent } from './i18n/i18n-list/i18n-list.component';
import { I18nDetailsComponent } from './i18n/i18n-details/i18n-details.component';
import { ConfigurationComponent } from "./configuration/configuration/configuration.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    data: {},
  },

  {
    path: 'i18n/:id/edit',
    component: I18nDetailsComponent,
    canActivate: [AuthGuard, PasswordNeedsChangeGuard],
    data: {
      title: 'title.i18nEdit',
      breadcrumb: [
        {
          label: '{{list}}',
          url: '/i18n',
        },
        {
          label: '{{details}}',
          url: '/i18n/:id/edit',
        },
      ],
    },
  },
  {
    path: 'i18n',
    component: I18nListComponent,
    canActivate: [AuthGuard, PasswordNeedsChangeGuard],
    data: {
      title: 'link.i18n',
      breadcrumb: [
        {
          label: '{{list}}',
          url: '/i18n',
        },
      ],
    },
  },
  {
    path: 'configuration',
    component: ConfigurationComponent,
    canActivate: [AuthGuard, PasswordNeedsChangeGuard],
    data: {
      title: 'link.configuration'
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'title.login',
    },
  },
  {
    path: '**',
    component: LoginComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
