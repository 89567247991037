<div fxLayout="column" class="login-form-main" fxLayoutAlign="center center" fxFill>
  <form [formGroup]="formGroup" (submit)="login()" autocomplete="off">
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between stretch" fxLayoutGap="30px" style="margin-bottom:20px">
      <div fxFlex.lt-lg="100%">
        <mat-card class="mat-elevation-z2" matRipple fxFlex="100%">
          <mat-card-content>
            <div class="choose-platform" [ngClass]="{'active': loginMode == LoginMode.FROM_COMPUTER }" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="20px">
              <div>
                <mat-icon>trolley</mat-icon>
              </div>
              <div class="icon-text">InterCars 2 Allegro v1.0</div>
            </div>
          </mat-card-content>
          <mat-card-footer></mat-card-footer>
        </mat-card>
      </div>
    </div>
    <div>

      <div fxLayout="column" class="login-form">
        <h2 class="icon-text">{{'login.fromComputer' | translate}}</h2>
        <br>
        <mat-divider></mat-divider>
        <br>
        <table class="example-full-width" style="width:100%">
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>{{'form.usernameOrEmail' | translate}}</mat-label>
                <input [autocomplete]="usernameId$ | async" autocomplete="new-password" matInput
                       formControlName="username" placeholder="{{'form.usernameOrEmail' | translate}}">
                <!--<mat-hint align="start"><strong>{{'form.pleaseProvideUsernameOrEmail' | translate}}</strong></mat-hint>-->
                <mat-error
                  *ngIf="!formGroup.valid">{{'form.required' | translate}}</mat-error>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-label>{{'form.password' | translate}}</mat-label>
                <input [autocomplete]="passwordId$ | async" autocomplete="new-password" matInput type="password"
                       formControlName="password" maxlength="20" placeholder="{{'form.password' | translate}}">
                <mat-hint align="start"><strong>{{'form.pleaseProvidePassword' | translate}}</strong></mat-hint>
              </mat-form-field>
            </td>
          </tr>

          <tr *ngIf="showError">
            <td class="error">{{'error.improperUsernameOrPassword' | translate}}</td>
          </tr>

        </table>
        <!--    </mat-card-content>
            <mat-card-actions>-->
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
          <button type="submit" mat-raised-button color="primary">Login</button>
        </div>

      </div>

    </div>
    <!--    </mat-card-actions>
      </mat-card>-->
  </form>
</div>
