import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { I18nService } from './i18n.service';

export class MissingI18nHandler implements MissingTranslationHandler {

  constructor(private readonly i18nService: I18nService) {
  }

  handle(params: MissingTranslationHandlerParams): any {
    /*if (!params.key.startsWith('routes.')) {
      this.i18nService.addMissingKey(params).subscribe(() => {
      });
    }*/
  }
}
