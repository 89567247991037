import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface NSDraggedEvent {
  x: number;
  y: number;
}

@Directive({
  selector: '[appNSDraggable]',
})
export class NSDraggableDirective implements OnInit, OnChanges {
  isDown = false;
  moved = false;
  initialPosition: NSDraggedEvent = {x: 0, y: 0};
  @Input() appNSDraggableDisabled = false;
  @Input() scale = 1;
  @Output() appNSDragged: EventEmitter<NSDraggedEvent> = new EventEmitter<NSDraggedEvent>(null);
  @Output() appNSDragStarted: EventEmitter<NSDraggedEvent> = new EventEmitter<NSDraggedEvent>(null);
  @Output() appNSDragFinished: EventEmitter<any> = new EventEmitter<any>(null);

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document,
  ) {
  }

  ngOnInit(): void {
    const el = this.elementRef.nativeElement;
    el.addEventListener('mousemove', event => {
      if (this.isDown && !this.appNSDraggableDisabled) {
        this.moved = true;
        this.appNSDragged.emit({
          x: Math.round((event.screenX - this.initialPosition.x) / this.scale),
          y: Math.round((event.screenY - this.initialPosition.y) / this.scale),
        });
      }
    });
    el.addEventListener('mouseup', () => {
      if (this.moved) {
        this.appNSDragFinished.emit();
      }
      this.isDown = false;
    });
    el.addEventListener('mouseleave', () => (this.isDown = false));
    el.addEventListener('mousedown', event => {
      this.appNSDragStarted.emit();
      this.initialPosition = {
        x: Math.round(event.screenX),
        y: Math.round(event.screenY),
      };
      this.isDown = true;
      this.moved = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
