import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { alreadyUsedValidator } from '../validators/already-used.validator';

export interface NameFormDialogData {
  name: string;
  title: string;
  alreadyUsed: Array<string>;
}

@Component({
  selector: 'app-name-form-dialog',
  templateUrl: './name-form-dialog.component.html',
  styleUrls: ['./name-form-dialog.component.scss'],
})
export class NameFormDialogComponent implements OnInit {
  title: string = 'missing window title';
  form: UntypedFormGroup;
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NameFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NameFormDialogData,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: new UntypedFormControl(this.data.name || '', [
        Validators.required,
        alreadyUsedValidator(this.data.alreadyUsed),
      ]),
    });
  }

  get name() {
    return this.form.get('name');
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
